import React from "react";

const SearchIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M2210 4195 c-298 -49 -560 -183 -770 -394 -263 -263 -416 -630 -397
-950 10 -161 57 -293 178 -496 65 -109 184 -244 279 -315 33 -25 20 -8 -47 62
-146 152 -222 283 -269 464 -23 85 -27 121 -28 254 0 132 3 168 23 245 60 232
178 439 358 625 443 460 1058 538 1538 195 216 -154 414 -415 512 -674 27 -72
53 -186 53 -234 0 -18 5 -39 11 -47 7 -11 9 5 4 55 -53 606 -455 1077 -1027
1201 -102 22 -313 27 -418 9z"
        />
        <path
          d="M2430 3860 c-251 -30 -505 -172 -673 -378 -55 -66 -147 -215 -147
-236 0 -5 24 30 53 79 64 109 207 262 312 335 165 113 325 169 511 177 160 7
289 -17 437 -81 70 -31 37 -5 -39 30 -137 64 -306 91 -454 74z"
        />
        <path
          d="M2340 3796 c-369 -77 -677 -389 -781 -791 -34 -133 -32 -340 5 -480
89 -338 331 -588 648 -669 32 -8 101 -17 155 -20 l98 -5 -100 14 c-91 12 -242
51 -233 60 2 2 34 -5 72 -16 88 -24 209 -40 321 -42 l87 -2 18 -48 18 -48 -26
-10 c-41 -16 -220 -39 -302 -39 -154 0 -319 37 -457 101 -104 47 -100 37 5
-16 187 -93 388 -124 592 -92 l75 12 31 -35 c26 -29 42 -70 87 -220 106 -358
207 -672 219 -688 24 -29 97 -54 137 -47 86 16 151 79 151 147 0 27 -233 737
-289 882 -6 16 -9 43 -6 60 5 23 0 43 -19 76 l-25 44 81 43 c267 143 451 358
549 640 42 119 36 123 -15 10 -111 -246 -267 -434 -472 -573 -157 -105 -309
-157 -459 -157 -207 0 -401 90 -584 271 -79 79 -97 107 -53 83 81 -43 326 -54
457 -20 44 11 98 24 120 28 22 5 36 9 30 10 -5 0 26 21 69 46 285 162 438 398
440 680 1 171 -44 297 -141 394 -32 32 -69 63 -81 70 -13 7 5 -16 41 -51 99
-98 145 -203 154 -358 15 -253 -105 -484 -332 -637 -155 -104 -294 -143 -512
-144 -124 0 -278 26 -310 53 -21 17 -87 134 -122 217 -151 356 -73 735 205
1002 132 125 272 198 459 239 76 17 263 14 337 -5 226 -59 438 -255 524 -485
26 -68 28 -87 29 -210 0 -116 -3 -143 -22 -194 -90 -237 -355 -477 -652 -587
-89 -33 -73 -38 25 -8 105 32 170 64 275 134 176 118 307 269 377 437 109 262
38 552 -186 759 -88 81 -158 123 -269 162 -80 28 -101 31 -233 33 -94 2 -168
-1 -210 -10z m-230 -1876 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10
-5 10 6 0 17 -5 25 -10z m640 -573 c126 -390 170 -520 183 -535 8 -11 6 -11
-15 -1 -17 7 -29 24 -36 48 -5 20 -40 130 -77 246 -115 361 -165 520 -165 528
0 5 4 6 10 2 5 -3 50 -133 100 -288z"
        />
        <path
          d="M2360 3507 c-186 -42 -352 -210 -390 -394 -19 -90 -8 -226 24 -303
55 -135 169 -239 308 -281 88 -27 214 -23 298 11 163 63 294 232 321 413 34
228 -103 463 -313 537 -74 26 -178 33 -248 17z m207 -80 c207 -54 344 -281
299 -498 -30 -142 -123 -260 -253 -322 -64 -30 -75 -32 -178 -32 -99 0 -115 3
-165 27 -30 15 -77 48 -105 73 l-50 46 59 -47 c79 -62 136 -85 226 -91 178
-13 348 86 424 247 28 59 31 74 30 160 0 52 -6 106 -12 120 -9 20 -10 12 -5
-45 18 -228 -123 -414 -347 -455 -35 -6 -88 -9 -117 -5 -117 14 -238 106 -296
225 -30 61 -32 72 -32 175 0 103 2 114 32 178 56 118 191 229 303 250 52 9
137 7 187 -6z m-456 -684 c13 -16 12 -17 -3 -4 -17 13 -22 21 -14 21 2 0 10
-8 17 -17z"
        />
        <path d="M3593 2905 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
        <path
          d="M3576 2814 c-3 -26 -4 -49 -2 -52 3 -2 8 17 11 43 4 25 5 48 3 51 -3
2 -8 -17 -12 -42z"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
