import styled from "styled-components";

export const InfomrationModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  background-color: #fff;
  border: unset;
  border-radius: 22px;
  text-align: center;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 50px;
  gap: 20px;

  @media (max-width: 768px) {
    min-width: 80%;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const AdditionalInfo = styled.span`
  margin-top: 20px;
  color: rgb(240 156 79);
  cursor: pointer;
`;

export const Close = styled.img`
  width: 20px;
  height: 20px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #0000008f;
  z-index: 5;
`;
