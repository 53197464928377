import React from "react";

const Tiles: React.FC<{ color: string }> = ({ color }) => {
  return (
    <>
      <svg
        fill={color}
        height="24px"
        width="24px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 297 297"
      >
        <g>
          <path
            d="M61.352,154.607c0,1.924,0.784,3.806,2.144,5.166c1.359,1.371,3.253,2.155,5.177,2.155c1.924,0,3.816-0.784,5.177-2.155
		c1.359-1.359,2.144-3.242,2.144-5.166c0-1.934-0.784-3.817-2.144-5.177c-1.36-1.37-3.253-2.154-5.177-2.154
		c-1.935,0-3.817,0.784-5.177,2.154C62.137,150.79,61.352,152.673,61.352,154.607z"
          />
          <path
            d="M56.469,149.713c1.934,0,3.816-0.774,5.177-2.145c1.37-1.359,2.144-3.241,2.144-5.166c0-1.935-0.774-3.817-2.144-5.177
		c-1.36-1.37-3.243-2.144-5.177-2.144c-1.924,0-3.817,0.774-5.177,2.144c-1.359,1.359-2.144,3.242-2.144,5.177
		c0,1.924,0.784,3.806,2.144,5.166C52.651,148.939,54.545,149.713,56.469,149.713z"
          />
          <path
            d="M44.275,161.928c1.924,0,3.806-0.784,5.177-2.155c1.359-1.359,2.144-3.242,2.144-5.166c0-1.934-0.784-3.817-2.144-5.177
		c-1.371-1.37-3.253-2.154-5.177-2.154c-1.935,0-3.817,0.784-5.177,2.154c-1.37,1.359-2.144,3.243-2.144,5.177
		c0,1.924,0.774,3.806,2.144,5.166C40.457,161.143,42.34,161.928,44.275,161.928z"
          />
          <path
            d="M152.335,154.607c0,1.924,0.784,3.806,2.144,5.166c1.359,1.371,3.253,2.155,5.177,2.155c1.924,0,3.816-0.784,5.177-2.155
		c1.359-1.359,2.144-3.242,2.144-5.166c0-1.934-0.784-3.817-2.144-5.177c-1.36-1.37-3.253-2.154-5.177-2.154
		c-1.924,0-3.817,0.784-5.177,2.154C153.119,150.79,152.335,152.673,152.335,154.607z"
          />
          <path
            d="M147.451,149.713c1.934,0,3.816-0.774,5.177-2.145c1.37-1.359,2.144-3.241,2.144-5.166c0-1.924-0.774-3.817-2.144-5.177
		c-1.36-1.37-3.243-2.144-5.177-2.144c-1.924,0-3.807,0.774-5.177,2.144c-1.359,1.359-2.144,3.242-2.144,5.177
		c0,1.924,0.784,3.816,2.144,5.166C143.644,148.939,145.527,149.713,147.451,149.713z"
          />
          <path
            d="M135.257,161.928c1.924,0,3.806-0.784,5.177-2.155c1.359-1.359,2.144-3.242,2.144-5.166c0-1.934-0.784-3.817-2.144-5.177
		c-1.36-1.37-3.253-2.154-5.177-2.154c-1.935,0-3.817,0.784-5.177,2.154c-1.37,1.359-2.144,3.243-2.144,5.177
		c0,1.924,0.774,3.806,2.144,5.166C131.44,161.143,133.322,161.928,135.257,161.928z"
          />
          <path
            d="M244.708,154.607c0,1.924,0.784,3.806,2.144,5.166c1.37,1.371,3.253,2.155,5.177,2.155c1.934,0,3.816-0.784,5.177-2.155
		c1.37-1.359,2.144-3.242,2.144-5.166c0-1.934-0.774-3.817-2.144-5.177c-1.36-1.37-3.243-2.154-5.177-2.154
		c-1.924,0-3.817,0.784-5.177,2.154C245.493,150.79,244.708,152.673,244.708,154.607z"
          />
          <path
            d="M239.835,149.713c1.924,0,3.806-0.774,5.177-2.145c1.359-1.359,2.144-3.241,2.144-5.166c0-1.935-0.784-3.817-2.144-5.177
		c-1.371-1.37-3.253-2.144-5.177-2.144c-1.935,0-3.817,0.774-5.187,2.144c-1.359,1.359-2.133,3.253-2.133,5.177
		c0,1.924,0.774,3.806,2.133,5.166C236.017,148.939,237.899,149.713,239.835,149.713z"
          />
          <path
            d="M227.631,161.928c1.924,0,3.816-0.784,5.177-2.155c1.359-1.359,2.144-3.242,2.144-5.166c0-1.934-0.784-3.817-2.144-5.177
		c-1.36-1.37-3.253-2.154-5.177-2.154c-1.935,0-3.817,0.784-5.177,2.154c-1.37,1.359-2.144,3.243-2.144,5.177
		c0,1.924,0.774,3.806,2.144,5.166C223.813,161.143,225.695,161.928,227.631,161.928z"
          />
          <path
            d="M286.542,0H10.458C4.682,0,0,4.682,0,10.458v276.085C0,292.318,4.682,297,10.458,297h276.085
		c5.775,0,10.458-4.682,10.458-10.458V10.458C297,4.682,292.318,0,286.542,0z M92.028,276.085H20.915v-71.113h71.113V276.085z
		 M92.028,184.056H20.915v-71.113h71.113V184.056z M92.028,92.028H20.915V20.915h71.113V92.028z M184.056,276.085h-71.113v-71.113
		h71.113V276.085z M184.056,184.056h-71.113v-71.113h71.113V184.056z M184.056,92.028h-71.113V20.915h71.113V92.028z
		 M276.085,276.085h-71.113v-71.113h71.113V276.085z M276.085,184.056h-71.113v-71.113h71.113V184.056z M276.085,92.028h-71.113
		V20.915h71.113V92.028z"
          />
        </g>
      </svg>
    </>
  );
};

export default Tiles;
