import styled from "styled-components";

export const Modal = styled.div`
  position: fixed;
  box-sizing: border-box;
  padding: 10%;
  text-align: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 200;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 5px;
  max-height: calc(100vh - 35px);
  overflow: auto;
  align-items: center;
`;

export const CategoryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 2px solid #eee;
  box-sizing: border-box;
  border-radius: 6px;
`;
