import React from "react";
import * as S from "./CategoryModal.styles";

const CategoryModal: React.FC<{
  listOfCategories: string[];
  selectionClicked: any;
}> = ({ listOfCategories, selectionClicked }) => {
  return (
    <S.Modal>
      <h1>Wybierz Kategorię</h1>
      <S.ListContainer>
        {listOfCategories.map((category) => (
          <S.CategoryRow
            key={category}
            onClick={() => selectionClicked(category)}
          >
            <h5>{category}</h5>
          </S.CategoryRow>
        ))}
      </S.ListContainer>
    </S.Modal>
  );
};

export default CategoryModal;
