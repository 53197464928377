import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  };
  p {
    padding: 0;
    margin: 0;
  };

  a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  };
  ::-webkit-scrollbar-thumb {
    background-color: #555557;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  };
  ::-webkit-scrollbar-track {
    inset: 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
    <Analytics />
  </React.StrictMode>
);
