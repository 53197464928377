import React from "react";

const Rows: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      fill={color}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 35 35"
    >
      <g>
        <g>
          <path d="M0,0v35h35V0H0z M33,33H2V2h31V33z" />
          <rect x="10" y="5" width="20" height="2.824" />
          <rect x="10" y="9.438" width="20" height="2.819" />
          <rect x="10" y="13.869" width="20" height="2.824" />
          <rect x="10" y="18.307" width="20" height="2.818" />
          <rect x="10" y="22.739" width="20" height="2.829" />
          <rect x="10" y="27.18" width="20" height="2.82" />
          <rect x="5" y="5" width="2.824" height="2.824" />
          <rect x="5" y="9.436" width="2.824" height="2.823" />
          <rect x="5" y="13.87" width="2.824" height="2.823" />
          <rect x="5" y="18.305" width="2.824" height="2.824" />
          <rect x="5" y="22.74" width="2.824" height="2.824" />
          <rect x="5" y="27.176" width="2.824" height="2.824" />
        </g>
      </g>
    </svg>
  );
};

export default Rows;
