import styled from "styled-components";

export const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 10%;
  height: 70px;
  align-items: center;
`;

export const LogoTextBig = styled.h1`
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(240 156 79);
  font-size: 30px;
`;

export const LogoTextSmall = styled.h1`
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(240 156 79);
  font-size: 20px;
  transform: translate(0, 2px);
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  cursor: pointer;

  &&:hover {
    opacity: 50%;
  }
`;

export const Logo = styled.img`
  height: 100px;
`;

export const SocialMediaIcon = styled.img`
  width: 30px;
  height: 30px;
  transform: translateY(2px);
`;

export const RightSideMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
`;

export const AdditionalInfo = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
`;

export const MenuTab = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.5s;
  background-color: inherit;
  color: #000;

  &&:hover {
    background-color: rgb(240 156 79);
    color: #fff;
    transition: 0.5s;
  }
`;
