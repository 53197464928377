import React, { useEffect, useRef, useState } from "react";
import * as S from "./Shop.styles";
import { products } from "./products";
import AddNewItemModal from "./AddNewItemModal/AddNewItemModal";
import SearchIcon from "../AdminPanel/SearchIcon";
import Tiles from "./Tiles";
import Rows from "./Rows";
import ArrowDown from "./ArrowDown";
import CategoryModal from "./CategoryModal/CategoryModal";
import { useNavigate, useParams } from "react-router";

const Shop = () => {
  const SHOP_STAGES = {
    LIST: "list",
    ITEM_PAGE: "item page",
  };

  const { LIST, ITEM_PAGE } = SHOP_STAGES;
  const navigate = useNavigate();

  const isAdmin = false;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedItemID, setSelectedItemID] = useState(-1);
  const [isAddNewItemModalOpen, setIsAddNewItemModalOpen] = useState(false);
  const [shopStage, setShopStage] = useState(LIST);
  const [isPhotoZoomed, setIsPhotoZoomed] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [filteredValue, setFilteredValue] = useState("");
  const [isSelectedTileMode, setIsSelectedTileMode] = useState(false);
  const [isCategoryModalOpen, setIsCategoryOpen] = useState(false);

  const { id, categoryName } = useParams();

  useEffect(() => {
    if (categoryName !== undefined) {
      if (!isNaN(Number(categoryName))) {
        navigate(`/sklep/wszystkie/${categoryName}`);
        setSelectedTab("Wszystkie");
      }
    }
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      setShopStage(ITEM_PAGE);
      setSelectedItemID(Number(id));
    } else {
      setShopStage(LIST);
      setSelectedItemID(-1);
    }
  }, [id]);

  const [items, setItems] = useState<
    {
      id: number;
      category: string;
      img: string;
      name: string;
      status: string;
      price: string;
    }[]
  >(products);

  const width = window.innerWidth;

  // useEffect(() => {
  //   fetch('https://gb-akwarystyka-server.vercel.app/products')
  //   .then((data) => data.json())
  //   .then((parsedData) => setItems(parsedData))
  //   .catch(error => console.log(error));
  // }, [])

  const selectedItemNode: any =
    selectedItemID !== -1 && items.length > 0
      ? items.find(({ id }) => id === selectedItemID)
      : undefined;

  const shopMenuList =
    items && items.length > 0
      ? ["Wszystkie", ...new Set(items.map(({ category }) => category))]
      : [];

  const [selectedTab, setSelectedTab] = useState(
    categoryName
      ? categoryName === "raki i kraby"
        ? "Raki i Kraby"
        : categoryName.charAt(0).toUpperCase() + categoryName.slice(1)
      : shopMenuList[0]
  );

  const listToRender =
    !isAdmin && selectedTab !== "Wszystkie"
      ? items.filter(
          ({ status, category }) =>
            status !== "hidden" && category === selectedTab
        )
      : items;

  useEffect(() => {
    if (selectedTab !== categoryName) {
      navigate(`/sklep/${selectedTab.toLowerCase()}${id ? `/${id}` : ""}`);
    }
  }, [selectedTab]);

  const newNameRef = useRef<any>();
  const newPriceRef = useRef<any>();
  const newDescriptionRef = useRef<any>();
  const inputRef = useRef<any>();

  const filteredList =
    filteredValue !== ""
      ? listToRender.filter(({ name }) =>
          name.toLowerCase().includes(filteredValue.toLowerCase())
        )
      : listToRender;

  const rowRef = useRef<any>();

  useEffect(() => {
    if (shopStage === LIST && width < 768) {
      window.scrollTo(0, scrollPosition);
    } else if (shopStage === LIST && width > 768) {
      if (rowRef.current !== null) {
        rowRef.current.scrollTop = scrollPosition;
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [shopStage, scrollPosition, selectedTab]);

  return (
    <S.ShopContainer $isOnDetailsPage={false}>
      <S.ShopPageCenterBox>
        {selectedItemID === -1 ? (
          <S.AdditionalContainer>
            {width > 768 ? (
              <S.AdditionalSetting>
                <S.AdditionalImageContainer
                  onClick={() => setIsSelectedTileMode(false)}
                >
                  <Rows
                    color={!isSelectedTileMode ? "rgb(240 156 79)" : "#000"}
                  />
                </S.AdditionalImageContainer>
                <S.AdditionalImageContainer
                  onClick={() => setIsSelectedTileMode(true)}
                >
                  <Tiles
                    color={isSelectedTileMode ? "rgb(240 156 79)" : "#000"}
                  />
                </S.AdditionalImageContainer>
              </S.AdditionalSetting>
            ) : null}
            <S.InputContainer onClick={() => inputRef.current?.focus()}>
              <S.ImageContainer>
                <SearchIcon />
              </S.ImageContainer>
              <S.InputFilter
                value={filteredValue}
                ref={inputRef}
                onChange={(e) => setFilteredValue(e.target.value)}
              />
            </S.InputContainer>
          </S.AdditionalContainer>
        ) : null}
        <S.StylingBox>
          {shopStage === LIST ? (
            <>
              {width > 768 ? (
                <S.LeftMenu>
                  {shopMenuList.map((stringMenu: string) => (
                    <S.Tab
                      key={stringMenu}
                      onClick={() => {
                        setFilteredValue("");
                        setScrollPosition(0);
                        setSelectedTab(stringMenu);
                      }}
                      isSelected={selectedTab === stringMenu}
                    >
                      <p>{stringMenu}</p>
                    </S.Tab>
                  ))}
                </S.LeftMenu>
              ) : (
                <S.SelectCategory onClick={() => setIsCategoryOpen(true)}>
                  <p>Wybierz Kategorie</p>
                  <S.ArrowDownIcon>
                    <ArrowDown color={`#000`} />
                  </S.ArrowDownIcon>
                </S.SelectCategory>
              )}

              <S.ContentContainer
                data-testid="Content_Container"
                ref={rowRef}
                $isSelectedTileMode={isSelectedTileMode}
              >
                {width > 768 && !isSelectedTileMode
                  ? filteredList.map(({ name, price, img, id, status }) => (
                      <S.FishRow
                        key={name + id + Math.random}
                        isHidden={status === "hidden"}
                        onClick={() => {
                          setScrollPosition(rowRef.current?.scrollTop);
                          // setSelectedItemID(id);
                          // setShopStage(ITEM_PAGE);
                          navigate(`/sklep/${categoryName}/${id}`);
                        }}
                      >
                        <S.LeftRowSide>
                          <S.FishPhoto
                            loading="lazy"
                            src={`${process.env.PUBLIC_URL}/assets/${img}.webp`}
                          />
                          <S.FishColumn>
                            <S.NameAndStatusColumn>
                              <p>{name}</p>
                            </S.NameAndStatusColumn>
                          </S.FishColumn>
                        </S.LeftRowSide>
                        <p>{price}</p>
                      </S.FishRow>
                    ))
                  : width < 768
                  ? filteredList.map(({ name, price, id, img }) => (
                      <S.ProductTile
                        key={name + id}
                        $isSelectedTileMode={isSelectedTileMode}
                        onClick={() => {
                          setScrollPosition(window.scrollY);
                          // setSelectedItemID(id);
                          // setShopStage(ITEM_PAGE);
                          navigate(`/sklep/${categoryName}/${id}`);
                        }}
                      >
                        <S.IconTileContainer>
                          <S.TileProductImage
                            loading="lazy"
                            src={`${process.env.PUBLIC_URL}/assets/${img}.webp`}
                          />
                        </S.IconTileContainer>
                        <S.RightMobileSide>
                          <S.TileMobileHeaderName>
                            {name}
                          </S.TileMobileHeaderName>
                          <S.TileMobileHeaderName>
                            <strong>{price}</strong>
                          </S.TileMobileHeaderName>
                        </S.RightMobileSide>
                      </S.ProductTile>
                    ))
                  : filteredList.map(({ name, price, id, img }) => (
                      <S.ProductTile
                        key={name + id}
                        $isSelectedTileMode={isSelectedTileMode}
                        onClick={() => {
                          setScrollPosition(rowRef.current?.scrollTop);
                          // setSelectedItemID(id);
                          // setShopStage(ITEM_PAGE);
                          navigate(`/sklep/${categoryName}/${id}`);
                        }}
                      >
                        <S.IconTileContainer>
                          <S.TileProductImage
                            loading="lazy"
                            src={`${process.env.PUBLIC_URL}/assets/${img}.webp`}
                          />
                        </S.IconTileContainer>
                        <S.TileHeaderName>{name}</S.TileHeaderName>
                        <S.TileHeaderName>{price}</S.TileHeaderName>
                      </S.ProductTile>
                    ))}
              </S.ContentContainer>
            </>
          ) : (
            <>
              <S.Return
                onClick={() => {
                  setSelectedItemID(-1);
                  setShopStage(LIST);
                  navigate(`/sklep/${selectedTab.toLowerCase()}`);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="1em"
                  height="1em"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="m15.547 2-1.305 1.27L6 11.293v1.414l8.242 8.022L15.547 22H17v-1.414l-1.305-1.271L8.18 12l7.515-7.316L17 3.414V2z"
                  ></path>
                </svg>
              </S.Return>
              <S.MoreDetilsContainer>
                <S.LeftItemSection>
                  <S.ItemImage
                    onClick={() => setIsPhotoZoomed(true)}
                    src={`${process.env.PUBLIC_URL}/assets/${
                      selectedItemNode && selectedItemNode.img
                    }.webp`}
                  />
                </S.LeftItemSection>
                <S.RightItemSection>
                  <S.TopInformation>
                    {isAdmin && isInEditMode ? (
                      <S.Input
                        ref={newNameRef}
                        defaultValue={selectedItemNode && selectedItemNode.name}
                      />
                    ) : (
                      <S.Text size="23px" weight="regular">
                        <b>{selectedItemNode && selectedItemNode.name}</b>
                      </S.Text>
                    )}
                    {isAdmin && isInEditMode ? (
                      <S.Input
                        defaultValue={
                          selectedItemNode && selectedItemNode.price
                        }
                        ref={newPriceRef}
                      />
                    ) : (
                      <S.Text size="18px" weight="bold">
                        Cena za sztukę:{" "}
                        {selectedItemNode && selectedItemNode.price}
                      </S.Text>
                    )}
                  </S.TopInformation>
                  {isAdmin && isInEditMode ? (
                    <S.TextArea
                      ref={newDescriptionRef}
                      defaultValue={
                        selectedItemNode && selectedItemNode.description
                      }
                    />
                  ) : (
                    <S.Text size="18px" weight="regular">
                      <strong>Opis:</strong> <br />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedItemNode.description,
                        }}
                      ></p>
                    </S.Text>
                  )}
                </S.RightItemSection>
              </S.MoreDetilsContainer>
            </>
          )}
        </S.StylingBox>
        {shopStage === ITEM_PAGE ? (
          <S.Footer>
            Po więcej informacji zapraszam do kontaktu telefonicznego 698954898.
            Nie wysyłamy ryb - odbiór jedynie osobiście po umówionej wizycie
            telefonicznie. Zachęcamy do sprawdzenia reszty naszej oferty
            dostępnej na OLX poprzez wpisanie frazy "GB" w wyszukiwaniu, w
            kategorii akwarystyka. *** SKLEP STACJONARNY I HODOWLA *** Odbiór
            osobisty w miejscowości Bystrzyca k. OŁAWY 55-200 Zapraszam po
            wcześniejszym umówieniu się telefonicznie w godzinach: -
            poniedziałek - piątek 10:00 - 20:00 - sobota 10:00 - 20:00 -
            niedziela 15:00 -20:00 Niniejsza oferta jest wyłącznie informacją
            handlową i nie stanowi oferty w myśl art.66, §1. Kodeksu Cywilnego.
            Sprzedający nie odpowiada za ewentualne błędy lub nieaktualność
            oferty.
          </S.Footer>
        ) : null}
      </S.ShopPageCenterBox>
      {isCategoryModalOpen ? (
        <CategoryModal
          listOfCategories={shopMenuList}
          selectionClicked={(x: string) => {
            setSelectedTab(x);
            setIsCategoryOpen(false);
          }}
        />
      ) : null}
    </S.ShopContainer>
  );
};

export default Shop;
