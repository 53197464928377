import React from "react";
import * as S from "./Menu.styles";
import { Link } from "react-router-dom";

const Menu: React.FC<{
  listOfTabs: string[];
  onClickHandler: (selectedTab: string) => void;
}> = ({ listOfTabs, onClickHandler }) => {
  const width = window.innerWidth;

  return (
    <S.MenuRow>
      <Link to="/">
        <S.LeftSide>
          {width < 768 ? (
            <S.Logo src={`${process.env.PUBLIC_URL}/assets/logo.png`} />
          ) : (
            <>
              <S.LogoTextBig>GB</S.LogoTextBig>
              <S.LogoTextSmall>akwarystyka</S.LogoTextSmall>
            </>
          )}
        </S.LeftSide>
      </Link>
      <S.RightSideMenu>
        {width < 768 ? (
          <div>
            <a
              href={"https://www.facebook.com/profile.php?id=61564302763635"}
              target="_blank"
            >
              <S.SocialMediaIcon
                src={`${process.env.PUBLIC_URL}/assets/facebook.svg`}
                alt="facebook"
              />
            </a>
          </div>
        ) : null}
        {listOfTabs.map((string) => (
          <S.MenuTab onClick={() => onClickHandler(string)} key={string}>
            <p>{string}</p>
          </S.MenuTab>
        ))}
        {width > 768 ? (
          <S.AdditionalInfo>
            <p>+48 698 954 898</p>
          </S.AdditionalInfo>
        ) : null}
      </S.RightSideMenu>
    </S.MenuRow>
  );
};

export default Menu;
