import React, { useRef, useState } from "react";
import * as S from "./LoginPage.styles";

const LoginPage: React.FC<{
  changeAdminValue: React.Dispatch<React.SetStateAction<boolean>>;
  changePage: React.Dispatch<React.SetStateAction<string>>;
}> = ({ changeAdminValue, changePage }) => {
  const inputRef = useRef<any>();
  const [isWrongPassword, setIsWrongPassword] = useState(false);

  const checkLogin = () => {
    const value = inputRef.current?.value;
    if (value === "bystrzyca123") {
      changeAdminValue(true);
      changePage("LandingPage");
    } else {
      setIsWrongPassword(true);
    }
  };

  return (
    <S.LoginPage>
      <S.LoginTab>
        <h1>Zaloguj Się</h1>
        <S.InputColumn>
          <p>Podaj hasło:</p>
          <S.Input
            type="password"
            ref={inputRef}
            onKeyUp={(e) => {
              e.key === "Enter" && checkLogin();
            }}
          />
          {isWrongPassword ? <S.WrongText>Błędne hasło</S.WrongText> : null}
        </S.InputColumn>
        <S.ConfirmButton onClick={() => checkLogin()}>
          <p>Zaloguj</p>
        </S.ConfirmButton>
      </S.LoginTab>
    </S.LoginPage>
  );
};

export default LoginPage;
