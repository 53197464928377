import styled from "styled-components";

export const LoginPage = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${process.env.PUBLIC_URL}/assets/slider.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

export const LoginTab = styled.div`
  width: 400px;
  height: 600px;
  background-color: transparent;
  backdrop-filter: blur(20px);
  border-radius: 22px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #fff;
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

export const WrongText = styled.p`
  color: #f24848d4;
`;

export const Input = styled.input`
  border-radius: 22px;
  padding: 10px 20px;
  border: unset;
  width: 100%;

  &&:focus-visible {
    outline: unset;
  }
`;

export const WrongPassword = styled.div`
  padding: 10px 20px;
  width: 100%;
  border-radius: 22px;
  border: 1px solid red;
`;

export const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  width: fit-content;
  padding: 15px 10px;
  cursor: pointer;

  &&:hover {
    color: #528aba !important;
    transition: 1s;
    border-radius: 8px;
  }
`;
